import React, { useEffect, useState } from "react";
import UserCompany from "./../assets/images/pemandangan.jpg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import noImage from "./../assets/images/avatar-1.png";
import imgCard from "./../assets/images/img_card.png";
import { ReactComponent as OnMyWay } from "./../assets/images/bi-cycle.svg";
import { ReactComponent as UnAvailable } from "./../assets/images/night.svg";
import { ReactComponent as InviteChat } from "./../assets/images/popup.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import io from "socket.io-client";
import axios from "axios";
import {
  BellAlertIcon,
} from "@heroicons/react/24/solid";
import MediaQuery from 'react-responsive'


// const socket = io.connect("http://localhost:3001");
// const socket = io.connect("http://node-chat-hello.kitereative.com");
const socket = io.connect("https://node.chatnew.kitereative.com");

function generateRandomId() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

const CompanyView = () => {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, code } = useParams();
  const [idRoom, setIdRoom] = useState("");
  const [idRoomBell, setIdRoomBell] = useState("");
  const [idPassing, setIdPassing] = useState("");
  const [imagePassing, setImagePassing] = useState("");
  const [namePassing, setNamePassing] = useState("");
  const [buttonState, setButtonState] = useState(false);
  const [buttonText, setButtonText] = useState("Bell");
  const [iconColorStatus, setIconColorStatus] = useState({});
  const [listenName, setListenName] = useState("");
  const [listenTime, setListenTime] = useState("");
  const [listenMessage, setListenMessage] = useState("");
  const [clickedUserId, setClickedUserId] = useState(null);
  // const isMobile = useMediaQuery({ query: '(max-width: 358px)' })


  // Tambahkan ID unik jika tidak ada
  React.useEffect(() => {
    const storedId = localStorage.getItem('uniqueId');
    // https://chatgpt.com/c/311f3295-5804-476f-bc81-59fbabf136de
    if (!code) {
      const uniqueId = Math.floor(Math.random() * 1000000000); // Atau cara lain untuk generate ID unik
      // localStorage.setItem('scanned', 'true');
      // localStorage.setItem('uniqueId', uniqueId);
      // history.replace(`/company/view/${id}/${uniqueId}`);
      navigate(`/company/view/${id}/${uniqueId}`, { replace: true });
    } else if (code) {
      navigate('/blank');
    }
  }, [id]);


  useEffect(() => {
    setTimeout(() => {
      navigate('/blank');
    }, 60000);
    // return () => clearTimeout(timer);
  }, [navigate])

  // useEffect(() => {
  //   // Verifikasi apakah QR telah dipindai
  //   // const scanned = localStorage.getItem('scanned');
  //   // const storedId = localStorage.getItem('uniqueId');

  //   // if (scanned !== 'true' || storedId === id) {
  //   //   navigate('/404'); // Arahkan ke halaman 404 jika tidak valid
  //   // }
  //   console.log('ada');

  // }, [code])


  const handleButtonClick = async (id_staff, name, image) => {
    const idRoomForChat = generateRandomId();
    setIdPassing(id_staff);
    setNamePassing(name);
    setImagePassing(image ?? 'noimage');
    console.log(id_staff);
    setClickedUserId(id_staff);
    // Mendapatkan tanggal sekarang
    const today = new Date();

    // Mengubah tanggal ke dalam format yang diinginkan (misalnya: "YYYY-MM-DD")
    const date = today.toISOString().split("T")[0];

    // Mendapatkan waktu sekarang dlm format 24 jam
    const hours = today.getHours().toString().padStart(2, "0"); // Ambil jam dengan format dua digit (00-23)
    const minutes = today.getMinutes().toString().padStart(2, "0"); // Ambil menit dengan format dua digit (00-59)
    const seconds = today.getSeconds().toString().padStart(2, "0"); // Ambil detik dengan format dua digit (00-59)
    const time = `${hours}:${minutes}:${seconds}`;

    if (idRoomBell !== "") {
      socket.emit("room_bell", idRoomBell);
      console.log("masuk");
      console.log(idRoomBell);
    }
    socket.emit("button_pressed", { id_staff, idRoomBell, idRoomForChat });
    console.log(idRoomForChat);
    setButtonText("Calling");
    setIconColorStatus(prevState => ({
      ...prevState,
      [id_staff]: "text-danger", // Set warna ikon menjadi text-success
    }));


    try {
      const transformedData = {
        // messages: message,
        room_id: idRoomBell,
        idroom_for_chat: idRoomForChat,
        // id_cust: uid,
        id_staff: id_staff,
        date: date,
        time: time,
        type: 'bell',
      };

      // Making a POST request to your server using Axios
      const response = await axios.post('https://hello-backend.kitereative.com/api/auth/chat-activity', transformedData);

      // Handle the response if needed
      console.log('Message sent successfully:', response.data);

      // Clearing the message input after sending
    } catch (error) {
      // Handle errors if the request fails
      console.error('Error sending message:', error);
    }

    // Setelah 10 detik, kembalikan teks tombol ke 'be  ll'
    setTimeout(() => {
      setButtonText("Bell");
    }, 60000);
    // }
    // Perbarui status tombol
    setButtonState(!buttonState);

    setTimeout(() => {
      setIconColorStatus(prevState => ({
        ...prevState,
        [id_staff]: "", // Kembalikan warna ikon ke semula (kosong)
      }),
        setShowApple(false)
      );
    }, 5000);

    setShowApple(true);
  };

  // useEffect(() => {
  //   socket.on("bellrings", (data) => {
  //     setDataBell(data);
  //   });
  // }, [socket]);
  // console.log(dataBell);

  const getUser = async () => {
    try {
      const req = await fetch(
        // "https://hello-backend.kitereative.com/api/auth/user-without-auth"
        `https://hello-backend.kitereative.com/api/auth/user-where-hash-card-code/${id}`
      );
      const res = await req.json();
      console.log(res.data);
      // Menggabungkan data staff ke dalam array tunggal
      const combinedData = res.data.flatMap(user => {
        return [user, ...user.staff.map(staff => staff.staff)];
      });
      setDatas(combinedData);
      console.log(combinedData);

      // Memeriksa apakah data kosong
      if (res.data.length === 0) {
        navigate("/404"); // Navigasi ke halaman 404 jika data kosong
      }
      // setFilter(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
    setIdRoomBell(generateRandomId() + "_" + id);
  }, []);

  // mendengarkan acara dari backend
  useEffect(() => {
    socket.on("bell_feedback", (data) => {
      setIdRoom(data.idRoomForChat);
      setListenName(data.name);
      setListenTime(data.datetime);
      setListenMessage(data.message);
      setShow(true);
      setButtonText("Bell");
      console.log(data);
      // setDataMyMessages((prevDatas) => [...prevDatas, data]);
      // setTestMessage(data);
      // setStopCountdown(true);
      // setDataMessages(data.data.data); //automatis 'todos' isinya adl 'res.data'
      // console.log(data.message);
      // setMessageReceived(data.message);
      // lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }, [socket]);

  // useEffect(() => {
  //   setIdRoomBell(generateRandomId() + "_" + id);
  // }, [buttonText]);

  // Temukan data staff berdasarkan staffId
  // const filteredDatas = datas.filter(
  //   (data) =>
  //     ((data.id == id && data.roles === "OWNER") || (data.ownership_id === id && data.roles === "STAFF") || (data.ownership_id === Number(id) && data.roles === "STAFF"))
  //   // ((data.id == id && data.roles === "OWNER") || (data.roles === "STAFF") || (data.roles === "STAFF"))

  //   // Search 
  //   // &&
  //   // (
  //   //   data.name.toLowerCase().includes(searchTerm.toLowerCase())        
  //   // )

  // );
  // console.log(filteredDatas);

  // Search start
  // const handleSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchTerm(value);
  // };




  // if (!selectedStaff) {
  //   return <div>Data staff tidak ditemukan.</div>;
  // }

  const handleClose = () => {
    setShow(false);
  };
  const handleClickChat = () => {
    console.log(idRoom);
    const imageName = imagePassing.split('/').pop();
    // setImageStaff(imageName)
    // console.log(imageName);
    navigate(`/public/chat/${idPassing}/${namePassing}/${idRoom}/${imageName}`);
  };

  const [showHelp, setShowHelp] = useState(false);
  const [showApple, setShowApple] = useState(false);

  const handleCloseHelp = () => {
    setShowHelp(false);
  };

  const handleClickHelp = () => {
    setShowHelp(true);
  };

  const handleCloseApple = () => {
    setShowApple(false);
  };


  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "100px",
      }}
    >
      {/* modal Help start */}
      <Modal show={showHelp} onHide={handleCloseHelp}>
        <Modal.Header className="bg-secondary">
          <Modal.Title className="font-weight-bold text-primary">
            Help to use - Android
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="mt-4">Step 1: On your phone, open "Settings".</h6>
          <hr />
          <h6 className="mt-4">
            Step 2: Press "Privacy" then "Permission Mesanger".
          </h6>
          <hr />
          <h6 className="mt-4">
            Step 3: Press the "Micro" authorization type.
          </h6>
          <hr />

          <h6 className="mt-4">
            Step 4: Press "Chrome", and change the permission to "Allow".
          </h6>
          <hr />
        </Modal.Body>
      </Modal>
      {/* modal Help end */}

      {/* modal Popup "Apple en cours" */}
      <Modal show={showApple} onHide={handleCloseApple} >
        <Modal.Header className="bg-primary">
          <Modal.Title className="font-weight-bold text-white">
            Bell envoyée
          </Modal.Title>
          <button type="button" className="btn-close mt-n1" aria-label="Close" onClick={handleCloseApple} style={{ border: "none", outline: "none", background: "transparent" }}>
            <FontAwesomeIcon className="" icon={faTimes} style={{ fontSize: '1.5em', color: 'white' }} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <h2 className="mt-4">Apple en cours</h2>
        </Modal.Body>
      </Modal>
      {/* modal Help end */}

      {/* modal listen Response Bell start */}
      <Modal show={show} onHide={handleClose} className="">
        <Modal.Header className="bg-primary">
          <Modal.Title className="font-weight-bold text-white">
            <span className="text-capitalize">{listenName}</span>
            <small className="d-block text-sm font-weight-lighter" style={{ fontSize: "14px" }}>{listenTime}</small>
            {/* <small className="d-block text-sm font-weight-lighter" style={{ fontSize: "14px" }}>{listenTime}</small> */}
          </Modal.Title>
          <button type="button" className="btn-close mt-n1" aria-label="Close" onClick={handleClose} style={{ border: "none", outline: "none", background: "transparent" }}>
            <FontAwesomeIcon className="" icon={faTimes} style={{ fontSize: '1.5em', color: 'white' }} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {
            listenMessage === 'JE NE SUIS PAS LA' ?
              <div className="bg-danger py-5 " style={{ borderRadius: "20px" }}>
                <span className="d-flex justify-content-center">
                  <UnAvailable fill="white" width={100} height={100} />
                </span>
                <h4 className="text-center text-white mt-4 text-capitalize">{listenMessage}</h4>
              </div>
              : listenMessage === "J'ARRIVE" ?
                <div className="bg-success py-5 " style={{ borderRadius: "20px" }}>
                  <span className="d-flex justify-content-center">
                    <OnMyWay fill="white" width={100} height={100} />
                  </span>
                  <h4 className="text-center text-white mt-4 text-capitalize">{listenMessage}</h4>
                </div>
                :
                <div className="bg-primary py-5 " style={{ borderRadius: "20px" }}>
                  <span className="d-flex justify-content-center">
                    <InviteChat fill="white" width={100} height={100} />
                  </span>
                  <h4 className="text-center text-white mt-4 text-capitalize">{listenMessage}</h4>
                </div>
          }

        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <button variant="secondary" onClick={handleClose}> */}

        {listenMessage === "J'ARRIVE" || listenMessage === 'JE NE SUIS PAS LA' ?
          <h5 className="text-center mb-4 text-uppercase  px-4">{listenName} {listenMessage}</h5> :
          <h5 className="text-center mb-4 text-uppercase  px-4">{listenName} vous invite au t{listenMessage}</h5>
        }

        {listenMessage === "J'ARRIVE" || listenMessage === 'JE NE SUIS PAS LA' ? "" : <button
          className="btn btn-danger btn-lg btn-block mt-3 shadow-none font-weight-bold"
          onClick={() => handleClickChat()}
        >
          <i className="fa fa-comment-dots pr-2"></i>
          Rejoindre
        </button>}
        {/* </Modal.Footer> */}
      </Modal>
      {/* modal listen Response Bell end */}

      <div className="row sticky-top" style={{ backgroundColor: "#408ACA" }}>
        <div className="col text-center text-white my-3">
          <span className="mx-auto font-weight-bold">Bienvenue</span>
          <div className="d-flex flex-row-reverse ">
            {/* <NavLink
              className=" text-white font-weight-bolder"
            onClick={() => handleClickHelp()}
            >
              <h5>?</h5>
            </NavLink> */}
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col text-center text-white">
          <h5 className="font-weight-bold ">
            Please call the person you're going to see
          </h5>
        </div>
      </div>
      {/* https://www.codeply.com/go/cDQQcNY8kP */}
      {/* search */}
      {/* <div className="row justify-content-center">
          <div
            className="col-6 py-5 mt-4 "
            style={{ backgroundColor: "#141414", borderRadius: "20px" }}
          >
            <div className="input-group ">
              <span className="input-group-append">
                <div className="input-group-text border-0 bg-transparent mr-n5">
                  <i className="fa fa-search"></i>
                </div>
              </span>
              <input
                type="search"
                className="form-control rounded-pill bg-transparent py-2 pr-5 pl-5 text-white"
                placeholder="Search.."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div> */}
      {
        loading ? (
          <div className="row justify-content-center mt-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {datas.map((data, i) => (
              <div
                className="row justify-content-center flex-wrap"
                style={{ cursor: 'pointer' }}
                key={i}
                onClick={() => handleButtonClick(data.id, data.name, data.image)}>
                <div
                  className=" col col-md-10 col-xl-6 py-5 mt-4 mb-4 d-flex flex-column mx-2 max-sm-0"
                  style={{
                    borderRadius: "20px",
                    backgroundImage: `url(${imgCard})`, // Ganti 'path/to/img_card.png' dengan path gambar Anda
                    backgroundSize: "cover", // Mengatur agar gambar latar belakang menutupi seluruh div
                    width: "100%", // Panjang div
                    height: "100%",// Lebar div
                  }}
                >

                  <div className="row">
                    <div className="col p-0 d-flex justify-content-center " >
                      {/* tablet keatas */}
                      <div className="d-none d-sm-block"
                        style={{
                          width: "240px",
                          height: "240px",
                          overflow: "hidden",
                          borderRadius: "50%"
                        }}
                      >
                        <img
                          src={
                            data.image
                              ? `https://hello-backend.kitereative.com/storage/app/public/${data.image}`
                              : noImage
                          }
                          className="img-fluid rounded-circle"
                          alt=""
                          // style={{ maxWidth: "160px" }}
                          style={{
                            border: "6px solid #00A5DA",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",

                          }}
                        />
                      </div>

                      {/* tablet kebawah / mobile*/}
                      <MediaQuery minWidth={359} maxWidth={575}>
                        <div className="py-3 px-2 "
                          style={{
                            width: "160px",
                            height: "180px",
                            overflow: "hidden",
                            borderRadius: "50%"
                          }}
                        >
                          <img
                            src={
                              data.image
                                ? `https://hello-backend.kitereative.com/storage/app/public/${data.image}`
                                : noImage
                            }
                            className="img-fluid rounded-circle"
                            alt=""
                            // style={{ maxWidth: "160px" }}
                            style={{
                              border: "6px solid #00A5DA",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",

                            }}
                          />
                        </div>
                      </MediaQuery>

                      {/* mobile kebawah*/}
                      <MediaQuery minWidth={200} maxWidth={358}>
                        <div className="py-3 px-2 my-auto"
                          style={{
                            width: "120px",
                            height: "140px",
                            overflow: "hidden",
                            borderRadius: "50%"
                          }}
                        >
                          <img
                            src={
                              data.image
                                ? `https://hello-backend.kitereative.com/storage/app/public/${data.image}`
                                : noImage
                            }
                            className="img-fluid rounded-circle"
                            alt=""
                            // style={{ maxWidth: "160px" }}
                            style={{
                              border: "6px solid #00A5DA",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </MediaQuery>

                    </div>
                    <div className="col d-flex flex-column justify-content-center " style={{ borderLeft: '4px solid #0076CB' }}>
                      <p className="text-center mt-n2 text-capitalize text-body d-block d-sm-none text-info  font-weight-bold">{data.name}</p>
                      <h3 className="text-center text-capitalize text-body d-none d-sm-block">{data.name}</h3>
                      <p className="font-16 text-danger mt-n4 mb-n1 text-center text-uppercase d-block d-sm-none">{data.city ?? "empty"}</p>
                      <p className="font-16 text-danger mt-n2 text-center text-uppercase d-none d-sm-block">{data.city ?? "empty"}</p>
                      <BellAlertIcon
                        width="80"
                        color="#00A5DA"
                        className={`mx-auto d-block d-sm-none ${iconColorStatus[data.id]}`}
                      // style={{ width: "74%" }} // Ukuran ikon pada mode mobile
                      />
                      <BellAlertIcon
                        width="160"
                        color="#00A5DA"
                        className={`mx-auto d-none d-sm-block ${iconColorStatus[data.id]}`} // Kelas responsif Bootstrap untuk mode tablet dan di atasnya
                      />
                    </div>
                  </div>
                </div>


              </div>

            ))}
          </>
        )
      }
      <footer>
        <div
          className="fixed-bottom py-2"
          style={{ backgroundColor: "#408ACA" }}
        >
          <NavLink style={{ textDecoration: "none" }}>
            <p className="text-center text-white">hellohome.casa</p>
          </NavLink>
        </div>
      </footer>
    </div >
  );
};

export default CompanyView;
