import React from "react";
import logo from "./../HelloHome.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  // const register = UserRegistration();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    photo: null,
  };
  const validationSchema = yup.object({
    name: yup.string().required().trim(),
    email: yup.string().required().email().trim(),
    password: yup.string().required().trim().min(6),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Password must match"),
    photo: yup.mixed().required(), //'mixed': jika berbentuk file, memakai 'mixed'
  });

  const onSubmit = async (values) => {
    console.log(values);
    await AuthService.register(
      values.name,
      values.email,
      values.password,
      values.photo
    ).then((response) => {
      // console.log(response);
      if (response.message === "pendaftaran success") {
        // Notifification success
        toast.success("Registration Success!", {
          position: "top-center",
          autoClose: 2000, //2 detik
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/login");
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Registration Failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
    // register(values.name, values.email, values.password, values.photo);
    // console.log(JSON.stringify(values, null, 2));
  };
  return (
    <section className="section">
      <div className="container mt-5">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
            <div className="login-brand">
              <img
                src={logo}
                alt="logo"
                width="300"
                className=""
              // className="shadow-light rounded-circle"
              />
              {/* LOGO */}
            </div>

            <div className="card card-primary">
              <div className="card-header">
                <h4>Register</h4>
              </div>

              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(props) => {
                    return (
                      <Form method="POST">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <Field
                            id="name"
                            type="text"
                            // className={
                            //   props.errors.name
                            //     ? "form-control is-invalid"
                            //     : "form-control"
                            // }
                            className="form-control"
                            name="name"
                          />

                          <ErrorMessage name="name">
                            {(error) => (
                              //   <div className="invalid-feedback">{error}</div>
                              <div className="text-danger">{error}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            id="email"
                            type="email"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage name="email">
                            {(error) => (
                              <div className="text-danger">{error}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <Field
                            id="password"
                            type="password"
                            className="form-control"
                            name="password"
                          />
                          <ErrorMessage name="password">
                            {(error) => (
                              <div className="text-danger">{error}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <Field
                            id="confirmPassword"
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                          />
                          <ErrorMessage name="confirmPassword">
                            {(error) => (
                              <div className="text-danger">
                                confirm password is a required field and must be a valid password
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroupFileAddon01"
                              >
                                Photo
                              </span>
                            </div>
                            <div className="custom-file">
                              <input
                                name="photo"
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "photo",
                                    e.target.files[0]
                                  )
                                }
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="inputGroupFile01"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <ErrorMessage name="photo">
                            {(error) => (
                              <div className="text-danger">{error}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            disabled={props.isSubmitting || !props.isValid}
                            className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                              ? "btn-secondary"
                              : "btn-primary"
                              }`}
                          >
                            {props.isSubmitting ? "Please Wait" : "Register"}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className="simple-footer">
              Copyright &copy; HelloHome 2024
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
