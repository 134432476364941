import React from "react";
import { Route, Routes, Switch } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Register from "../pages/Register";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import User from "../pages/User";
import AddUser from "../pages/AddUser";
import UpdateUser from "../pages/UpdateUser";
import Product from "../pages/Product";
import Transaction from "../pages/Transaction";
import AddProduct from "../pages/AddProduct";
import CompanyList from "../pages/CompanyList";
import CompanyView from "../pages/CompanyView";
import Chat from "../pages/Chat";
import Subscribe from "../pages/Subscribe";
import Purchase from "../pages/Purchase";
import AddSubscribe from "../pages/AddSubscribe";
import AddPurchase from "../pages/AddPurchase";
import UpdatePurchase from "../pages/UpdatePurchase";
import UpdateProduct from "../pages/UpdateProduct";
import DetailTransaction from "../pages/DetailTransaction";
import NotFound from "../pages/NotFound";
import BlankPage from "../pages/BlankPage";

const SetupRouter = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/dashbord" element={<Dashboard />} />
        <Route path="user" element={<User />} />
        <Route path="user/add" element={<AddUser />} />
        <Route path="user/update/:id" element={<UpdateUser />} />

        <Route path="product" element={<Product />} />
        <Route path="product/add" element={<AddProduct />} />
        <Route path="product/update/:id" element={<UpdateProduct />} />

        <Route path="subscribe" element={<Subscribe />} />
        <Route path="subscribe/add" element={<AddSubscribe />} />
        <Route path="subscribe/update/:id" element={<UpdateUser />} />

        <Route path="purchase" element={<Purchase />} />
        <Route path="purchase/add" element={<AddPurchase />} />
        <Route path="purchase/update/:id" element={<UpdatePurchase />} />

        <Route path="transaction" element={<Transaction />} />
        <Route path="transaction/add" element={<AddUser />} />
        <Route path="transaction/update/:id" element={<UpdateUser />} />
        <Route path="transaction/details/:id" element={<DetailTransaction />} />

        <Route path="chat" element={<Chat />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="public/chat/:idstaff/:namestaff/:idroom/:imagestaff" element={<Chat />} />

        {/* <Route path="admin/chat" element={<Chat />} /> */}

        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="/" element={<CompanyList />} />
        <Route path="company/view/:id" element={<CompanyView />} />
        <Route path="company/view/:id/:code" element={<CompanyView />} />
      </Route>

      {/* Rute 404 */}
      <Route path="*" element={<NotFound />} />
      <Route path="blank" element={<BlankPage />} />
    </Routes>
  );
};

export default SetupRouter;
