import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import QRCode from "qrcode.react";
import auth from "../services/AuthHeader";
import {
  ArrowDownTrayIcon,
  LinkIcon
} from "@heroicons/react/24/solid";

const UpdatePurchase = () => {
  // const authTokenType = auth.AuthHeaderWithType();
  const authToken = auth.AuthHeader();
  const authTokenType = auth.AuthHeaderWithType();

  const { id } = useParams();
  const [defaultValueUserName, setDefaultValueUserName] = useState(null);
  const [defaultValueProductName, setDefaultValueProductName] = useState(null);
  const [defaultValueStatus, setDefaultValueStatus] = useState(null);
  const [dataProduct, setDataProduct] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    id: id,
    // nick_name: "",
    name: "",
    recipient: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zip_code: "",
    products_id: "",
    payment_id: "",
    payment_method: "",
    start_date: "",
    // expired_date: "",
    status: "",
    price: "",
    card_code: "",
    card_code_hash: "",
  });

  const status = [
    { value: "pending", label: "Pending" },
    { value: "on process", label: "On Process" },
    { value: "delivered ", label: "Delivered" },
    { value: "completed", label: "Completed " },
    { value: "canceled", label: "Canceled" },
    { value: "block", label: "Block" },
  ];

  const downloadQR = (data) => {
    const canvas = document.getElementById(data);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = data + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getUser = async () => {
    try {
      const req = await fetch(
        "https://hello-backend.kitereative.com/api/bestari/user",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      // Mengubah format data dari API sesuai dengan format yang diterima oleh react-select
      const formattedData = res.data.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setData(formattedData);
    } catch (error) {
      console.log(error);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const getProduct = async () => {
    try {
      const req = await fetch(
        "https://hello-backend.kitereative.com/api/bestari/product",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      // Mengubah format data dari API sesuai dengan format yang diterima oleh react-select
      const formattedDataProduct = res.data.map((product) => ({
        value: product.id,
        label: product.name,
      }));
      setDataProduct(formattedDataProduct);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios
      .get(
        "https://hello-backend.kitereative.com/api/bestari/purchase-card-show/" + id,
        {
          headers: authToken,
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setValues({
          ...values,
          // nick_name: res.data.data.nick_name,
          users_id: res.data.data.users_id,
          recipient: res.data.data.recipient,
          email: res.data.data.email,
          phone: res.data.data.phone,
          address: res.data.data.address,
          city: res.data.data.city,
          zip_code: res.data.data.zip_code,
          products_id: res.data.data.products_id,
          payment_id: res.data.data.payment_id,
          payment_method: res.data.data.payment_method,
          start_date: res.data.data.start_date,
          // expired_date: res.data.data.expired_date,
          status: res.data.data.status,
          price: res.data.data.price,
          card_code: res.data.data.card_code,
          card_code_hash: res.data.data.card_code_hash,
        });

        setDefaultValueUserName({
          value: res.data.data.users_id,
          label: res.data?.data?.user?.name,
        });

        setDefaultValueProductName({
          value: res.data.data.products_id,
          label: res.data?.data?.product?.name,
        });

        setDefaultValueStatus({
          value: res.data.data.status,
          label: res.data?.data?.status
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase()),
        });

        getUser();
        getProduct();
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();

  const initialValues = {
    // nick_name: values.nick_name,
    users_id: values.users_id,
    recipient: values.recipient,
    email: values.email,
    phone: values.phone,
    address: values.address,
    city: values.city,
    zip_code: values.zip_code,
    products_id: values.products_id,
    payment_id: values.payment_id,
    payment_method: values.payment_method,
    start_date: values.start_date,
    // expired_date: values.expired_date,
    status: values.status,
    price: values.price,
    card_code: values.card_code,
    card_code_hash: values.card_code_hash,
  };

  const validationSchema = yup.object({
    // nick_name: yup.string().required().trim(),
    users_id: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("phone is a required field"),
    recipient: yup.string().required().trim(),
    email: yup.string().required().email().trim(),
    phone: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("phone is a required field"),
    address: yup.string().required().trim(),
    city: yup.string().required().trim(),
    zip_code: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("zip code is a required field"),
    products_id: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("phone is a required field"),
    payment_id: yup.string().required().trim(),
    payment_method: yup.string().required().trim(),
    start_date: yup.string().required("date purchase is a required field").trim(),
    // expired_date: yup.string().required().trim(),
    status: yup.string().required().trim(),
    price: yup
      .number()
      .integer("the value must be an integer")
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("the value must be positive")
      .required("price is a required field"),
    card_code: yup.string().required().trim(),
  });

  const onSubmit = async (values) => {
    let postData = {
      // nick_name: values.nick_name,
      users_id: values.users_id,
      recipient: values.recipient,
      email: values.email,
      phone: values.phone,
      address: values.address,
      city: values.city,
      zip_code: values.zip_code,
      products_id: values.products_id,
      payment_id: values.payment_id,
      payment_method: values.payment_method,
      start_date: values.start_date,
      // expired_date: values.expired_date,
      status: values.status,
      price: values.price,
      card_code: values.card_code,
    };

    return await axios
      .post(
        "https://hello-backend.kitereative.com/api/bestari/purchase-card/" + id,
        postData,
        {
          headers: authToken,
        }
      )
      .then((response) => {
        if (response.data.message === "true") {
          // Notifification success
          toast.success("Update Purchase Success!", {
            position: "top-center",
            autoClose: 2000, //2 detik
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/purchase");
            // window.location.reload();
          }, 2000);
        } else {
          toast.error("Update Purchase Failed", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="section-header">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <h1>Purchases</h1>
      </div>
      <div className="card card-info">
        <div className="card-header">
          <h4>Edit Purchase</h4>
        </div>
        <div className="card-body ">
          <div className="row justify-content-center">
            <div className="col-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {(props) => {
                  return (
                    <Form method="POST">
                      {loading ? (
                        <p>Loading</p>
                      ) : (
                        <>
                          <div className="form-group">
                            <label>User Name</label>
                            <Select
                              options={data}
                              name="users_id"
                              defaultValue={defaultValueUserName}
                              // onChange={(e) => console.log(e)}
                              onChange={(e) =>
                                // console.log(e.value)
                                setValues({
                                  ...values,
                                  users_id: e.value,
                                })
                              }
                            />
                            <ErrorMessage name="users_id">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label>Product Name</label>
                            <Select
                              options={dataProduct}
                              name="products_id"
                              defaultValue={defaultValueProductName}
                              // onChange={(e) => console.log(e)}
                              onChange={(e) =>
                                // console.log(e.value)
                                setValues({
                                  ...values,
                                  products_id: e.value,
                                })
                              }
                            />
                            <ErrorMessage name="products_id">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="recipient">Recipient</label>
                            <Field
                              id="recipient"
                              type="text"
                              className="form-control"
                              name="recipient"
                              value={values.recipient}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  recipient: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="recipient">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field
                              id="email"
                              type="email"
                              className="form-control"
                              name="email"
                              value={values.email}
                              onChange={(e) =>
                                setValues({ ...values, email: e.target.value })
                              }
                            />
                            <ErrorMessage name="email">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <Field
                              id="phone"
                              type="number"
                              className="form-control"
                              name="phone"
                              value={values.phone}
                              onChange={(e) =>
                                setValues({ ...values, phone: e.target.value })
                              }
                            />
                            <ErrorMessage name="phone">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="Textarea1">Address</label>
                            <Field
                              as="textarea"
                              className="form-control"
                              id="Textarea1"
                              name="address"
                              rows="30"
                              placeholder="Address"
                              style={{ height: "100px" }}
                              value={values.address}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  address: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="address">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="city">City</label>
                            <Field
                              id="city"
                              type="text"
                              className="form-control"
                              name="city"
                              value={values.city}
                              onChange={(e) =>
                                setValues({ ...values, city: e.target.value })
                              }
                            />
                            <ErrorMessage name="city">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="zip_code">Zip Code</label>
                            <Field
                              id="zip_code"
                              type="number"
                              className="form-control"
                              name="zip_code"
                              value={values.zip_code}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  zip_code: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="zip_code">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="payment_id">Payment Id</label>
                            <Field
                              id="payment_id"
                              type="text"
                              className="form-control"
                              name="payment_id"
                              value={values.payment_id}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  payment_id: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="payment_id">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="payment_method">
                              Payment Method
                            </label>
                            <Field
                              id="payment_method"
                              type="text"
                              className="form-control"
                              name="payment_method"
                              value={values.payment_method}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  payment_method: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="payment_method">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="start_date">Date Purchase</label>
                            <Field
                              id="start_date"
                              type="date"
                              className="form-control"
                              name="start_date"
                              value={values.start_date}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  start_date: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="start_date">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          {/* <div className="form-group">
                            <label htmlFor="expired_date">Expired Date</label>
                            <Field
                              id="expired_date"
                              type="date"
                              className="form-control"
                              name="expired_date"
                              value={values.expired_date}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  expired_date: e.target.value,
                                })
                              }
                            />
                            <ErrorMessage name="expired_date">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div> */}

                          <div className="form-group">
                            <label>Status</label>
                            <Select
                              options={status}
                              name="status"
                              // onChange={(e) => console.log(e)}
                              defaultValue={defaultValueStatus}
                              onChange={(e) =>
                                // console.log(e.value)
                                setValues({
                                  ...values,
                                  status: e.value,
                                })
                              }
                            />
                            <ErrorMessage name="status">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="price">Price (€)</label>
                            <Field
                              id="price"
                              type="text"
                              className="form-control"
                              name="price"
                              value={values.price}
                              onChange={(e) =>
                                setValues({ ...values, price: e.target.value })
                              }
                            />
                            <ErrorMessage name="price">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-group">
                            <label htmlFor="card_code" className="d-block">Card Code</label>

                            <Field
                              disabled
                              id="card_code"
                              placeholder="XXXX-XXXX-XXXX"
                              type="text"
                              className="form-control w-50 mr-3 d-inline"
                              name="card_code"
                              maxLength={14} // Batasi inputan hanya sampai 14 karakter
                              value={values.card_code}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  card_code: e.target.value,
                                })
                              }
                            />
                            <div className="d-none">
                              <QRCode
                                id={`qr_${values.card_code}`}
                                value={`https://frontend-hello.kitereative.com/company/view/${values.card_code_hash}` ?? ''}
                                size={500}
                                level={"H"}
                                includeMargin={true}
                              />
                            </div>
                            <a href="#" className="text-white mr-3" onClick={() => downloadQR(`qr_${values.card_code}`)}>
                              <ArrowDownTrayIcon width="26" className="" color="#00A5DA" />
                              {/* <p>{row.ownership_id ?? row.id}</p> */}
                            </a>
                            <a href="#" className="text-white ml-1" onClick={(e) => {
                              e.preventDefault();
                              const downloadUrl = `https://frontend-hello.kitereative.com/company/view/${values.card_code_hash ?? ''}`;
                              navigator.clipboard.writeText(downloadUrl)
                                .then(() => alert('Copy URL has been copied!'))
                                .catch((err) => console.error('Failed to copy URL:', err));
                            }}>
                              <LinkIcon width="26" className="" color="#00A5DA" />
                            </a>

                            <ErrorMessage name="card_code">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div>



                          <div className="form-group">
                            <button
                              type="submit"
                              disabled={props.isSubmitting || !props.isValid}
                              className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                ? "btn-secondary"
                                : "btn-primary"
                                }`}
                            >
                              {props.isSubmitting ? "Please Wait" : "Update"}
                            </button>
                          </div>
                        </>
                      )}
                      {/* <div className="form-group">
                        <label htmlFor="nick_name">Nick Name</label>
                        <Field
                          id="nick_name"
                          type="text"
                          className="form-control"
                          name="nick_name"
                          value={values.nick_name}
                          onChange={(e) =>
                            setValues({ ...values, nick_name: e.target.value })
                          }
                        />
                        <ErrorMessage name="nick_name">
                          {(error) => (
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div> */}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className="card-footer "></div>
      </div>
    </>
  );
};

export default UpdatePurchase;
