import React, { useEffect, useState } from "react";
import {
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import DataTable from "react-data-table-component";
import auth from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import noImage from "./../assets/images/img09.jpg";

const Product = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const columns = [
    // {
    //   name: "Unique ID",
    //   selector: (row) => row.unique_id,
    //   sortable: true,
    // },
    {
      name: "No",
      cell: (row, index) => index + 1,
      // grow: -1,
      center: true,
      width: "80px",
      // sortable: true,
    },
    {
      name: "Image",
      center: true,

      // selector: (row) => row.image,
      selector: (row) => (
        <img
          className="p-2 img-thumbnail"
          // height={70}
          width={80}
          src={
            row.image ?
              "https://hello-backend.kitereative.com/storage/app/public/" + row.image :
              noImage
          }
          alt=""
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => {
        // Gunakan toUpperCase() untuk memastikan pengurutan berdasarkan huruf besar atau kecil
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      },
    },
    // {
    //   name: "Category",
    //   selector: (row) => row.category,
    //   sortable: true,
    // },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      format: (row) => `€${row.price}`,
      sortFunction: (a, b) => a.price - b.price,
      // sortFunction: (a, b) => a - b,
    },
    {
      name: "Visibility",
      selector: (row) =>
        row.visibility ? (
          <CheckCircleIcon width="24" className="text-success" />
        ) : (
          <XCircleIcon width="24" className="text-danger" />
        ),
      sortable: true,
      width: "120px",
      sortFunction: (a, b) => {
        // Anda perlu mengembalikan nilai perbandingan untuk sorting di sini
        // Misalnya, Anda dapat menggunakan nilai boolean untuk visibility
        if (a.visibility === b.visibility) return 0;
        return a.visibility ? 1 : -1;
      },
    },
    // {
    //   name: "Visibility",
    //   selector: (row) => row.visibility,
    //   cell: (row) =>
    //     row.visibility ? (
    //       <CheckCircleIcon width="24" className="text-success" />
    //     ) : (
    //       <XCircleIcon width="24" className="text-danger" />
    //     ),
    //   sortable: true,
    //   width: "120px",
    //   sortFunction: (a, b) => {
    //     // Gantilah dengan fungsi perbandingan sesuai kebutuhan Anda
    //     if (a === b) return 0;
    //     return a ? 1 : -1;
    //   },
    // },
    {
      name: "Type",
      selector: (row) =>
        row.type === "subscription"
          ? "Subscription"
          : row.type === "purchase_card"
            ? "Purchase Card"
            : "Null",
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      center: true,
      cell: (row) => (
        <div className="flex justify-conten-center">
          <NavLink
            className="btn btn-warning mx-1 my-1"
            to={`/product/update/${row.id}`}
          >
            <PencilSquareIcon width="18" />
          </NavLink>

          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => handleClickDelete(row.id)}
          >
            <TrashIcon width="18" />
          </button>
        </div>
      ),
    },
  ];

  const getProduct = async () => {
    try {
      const req = await fetch(
        "https://hello-backend.kitereative.com/api/bestari/product",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      setData(res.data);
      setFilter(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    const result = data.filter((item) => {
      return item.name?.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  // delete modal
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDelete = () => {
    // console.log(id);
    // const newdata = data.filter((item) => item.id !== deleteId);
    // setFilter(newdata);

    return axios
      .delete(`https://hello-backend.kitereative.com/api/bestari/product/${deleteId}`, {
        headers: authTokenType,
      })
      .then((res) => {
        setShow(false);
        setSearch("");
        // window.location.reload();
        getProduct();

        console.log(res);
      }); //karena membutuhkan id, jadi seperti ini
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#408ACA",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-header">
        <h1>Products</h1>
      </div>

      {/* <div className="section-body"> */}
      <div className="card">
        <div className="card-header">
          <h4>All Products</h4>
        </div>
        <DataTable
          className="px-4"
          customStyles={tableHeaderstyle}
          columns={columns}
          // data={data}
          data={filter}
          pagination
          // selectableRows
          fixedHeader
          fixedHeaderScrollHeight="600px"
          selectableRowsHighlight
          highlightOnHover
          // actions={
          //   <NavLink end to={"/add-user"} className="btn btn-success">
          //     Add Product
          //   </NavLink>
          // }
          subHeader
          subHeaderComponent={
            <>
              <NavLink
                end
                to={"/product/add"}
                className="btn btn-success mr-auto"
              >
                Add Product
              </NavLink>
              <input
                type="text"
                className="form-control w-25 mb-2 mr-4"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
          subHeaderAlign="right"
        ></DataTable>
      </div>
    </>
  );
};

export default Product;
