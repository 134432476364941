import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import auth from "../services/AuthHeader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DetailTransaction = () => {
  const authTokenType = auth.AuthHeaderWithType();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState(null);
  console.log(id);

  // Event handler untuk tombol kembali
  const handleGoBack = () => {
    navigate(-1); // Kembali ke halaman sebelumnya dalam history
    // Atau Anda dapat menggunakan navigate('/path') untuk pindah ke path tertentu
  };

  useEffect(() => {
    axios
      .get("https://hello-backend.kitereative.com/api/bestari/transaction-show/" + id, {
        headers: authTokenType,
      })
      .then((res) => {
        setdata(res.data.data);
      });
  }, [id]);

  return (
    <>
      <div className="section-header">
        <h1>Detail Subscription</h1>
      </div>
      <div className="secion-body invoice">
        <div className="table-responsive">
          <table className="table table-striped table-hover table-md">
            <tbody>
              <tr>
                <th data-width="40" style={{ width: 40 }}>
                  ID
                </th>
                <th className="text-center">User Name</th>
                <th className="text-center">Product</th>
                <th className="text-center">Price</th>
                <th className="text-center">Payment Id</th>
                <th className="text-center">Payment Method</th>
                <th className="text-center">Start Date</th>
                <th className="text-center">Expired Date</th>
                <th className="text-center">Status</th>
              </tr>
              <tr>
                <td style={{ width: 90 }}>HH-{data?.id}</td>
                <td className="text-center">{data?.user?.name}</td>
                <td className="text-center">{data?.product?.name}</td>
                <td className="text-center">€{data?.price}</td>
                <td className="text-center">{data?.payment_id}</td>
                <td className="text-center">{data?.payment_method}</td>
                <td className="text-center">{data?.start_date}</td>
                <td className="text-center">{data?.expired_date}</td>
                <td className="text-center">
                  {data?.status === "PENDING" ? (
                    <span className={`badge badge-warning`}>
                      {data?.status}
                    </span>
                  ) : data?.status === "SUCCESS" ? (
                    <span className={`badge badge-success`}>
                      {data?.status}
                    </span>
                  ) : (
                    <span className={`badge badge-danger`}>{data?.status}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn btn-primary" onClick={handleGoBack}>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailTransaction;
