import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
// import UserRegistration from "../hooks/UserRegistration";
import AuthService from "../services/auth.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
    CheckCircleIcon,
    XCircleIcon,
} from "@heroicons/react/24/solid";
import Select from "react-select";
import axios from "axios";
import auth from "../services/AuthHeader";

const AddPurchase = () => {
    const navigate = useNavigate();
    const authTokenType = auth.AuthHeaderWithType();

    const [data, setData] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);
    const [autoCompleteData, setAutoCompleteData] = useState({
        email: "",
        phone: "",
        address: "",
        city: "",
        zip_code: "",
        price: "",
    });
    const [disabledInput, setDisabledInput] = useState(false);
    const [isCardCodeUnique, setIsCardCodeUnique] = useState('');
    const [loading, setLoading] = useState(true);
    const [isLoadingCekCode, setIsLoadingCekCode] = useState(false); // State untuk status loading

    const status = [
        { value: "pending", label: "Pending" },
        { value: "on process", label: "On Process" },
        { value: "delivered ", label: "Delivered" },
        { value: "completed", label: "Completed " },
        { value: "canceled", label: "Canceled" },
        { value: "block", label: "Block" },
    ];

    const initialValues = {
        // nick_name: "",
        users_id: "",
        recipient: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip_code: "",
        products_id: "",
        payment_id: "",
        payment_method: "",
        start_date: "",
        // expired_date: "",
        status: "",
        price: "",
        card_code: "",
    };

    const validationSchema = yup.object({
        // nick_name: yup.string().required().trim(),
        users_id: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            .nullable(),
        recipient: yup.string().nullable().trim(),
        email: yup.string().nullable().email().trim(),
        phone: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            .nullable(),
        address: yup.string().nullable().trim(),
        city: yup.string().nullable().trim(),
        zip_code: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            .nullable(),
        products_id: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            .required("product is a required field"),
        payment_id: yup.string().nullable().trim(),
        payment_method: yup.string().nullable().trim(),
        start_date: yup.string().nullable().trim(),
        // expired_date: yup.string().required().trim(),
        status: yup.string().required().trim(),
        price: yup
            .number()
            .integer("the value must be an integer")
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive("the value must be positive")
            .required("price is a required field"),
        card_code: yup.string()
            .required('card code is a required field')
            .trim()
            .length(14, 'card code must be exactly 14 characters long')
        // .matches(/^\d{4}-\d{4}-\d{4}$/, 'Card code must be in XXXX-XXXX-XXXX format'), // Menambahkan pola regex untuk memastikan format XXXX-XXXX-XXXX,
    });

    const getUser = async () => {
        try {
            const req = await fetch(
                "https://hello-backend.kitereative.com/api/bestari/user",
                {
                    headers: authTokenType,
                }
            );
            const res = await req.json();
            // Mengubah format data dari API sesuai dengan format yang diterima oleh react-select
            const formattedData = res.data.map((user) => ({
                value: user.id,
                label: user.name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                zip_code: user.zip_code,
                price: user.price,
            }));
            console.log(res.data);
            setData(formattedData);
        } catch (error) {
            console.log(error);
        }
        // finally {
        //   setLoading(false);
        // }
    };

    const getProduct = async () => {
        try {
            const req = await fetch(
                "https://hello-backend.kitereative.com/api/bestari/product",
                {
                    headers: authTokenType,
                }
            );
            const res = await req.json();
            // Mengubah format data dari API sesuai dengan format yang diterima oleh react-select
            const formattedDataProduct = res.data.map((product) => ({
                value: product.id,
                label: product.name,
                price: product.price,
            }));
            setDataProduct(formattedDataProduct);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        try {
            getUser();
            getProduct();
            // setLoading(false);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, []);

    // Fungsi untuk memeriksa keunikan card code
    const checkCardCodeUniqueness = async (cardCode) => {
        try {
            const response = await axios.get(`https://hello-backend.kitereative.com/api/bestari/unique-cardcode/${cardCode}`,
                {
                    headers: authTokenType,
                });
            console.log(response.data.message);
            setIsCardCodeUnique(response.data.message); //isinya 'available' dan 'unavailable'
            setIsLoadingCekCode(true); // Set isLoading menjadi true saat memulai pemrosesan
        } catch (error) {
            // console.error('Error checking card code uniqueness:', error);
            setIsCardCodeUnique('unavailable');
            // Handle error
        } finally {
            setIsLoadingCekCode(false); // Set isLoading kembali menjadi false setelah pemrosesan selesai
        }
    }

    console.log(autoCompleteData);
    console.log(data);



    const onSubmit = async (values) => {
        const postData = {
            // nick_name: values.nick_name,
            users_id: values.users_id,
            recipient: values.recipient,
            email: values.email,
            phone: values.phone,
            address: values.address,
            city: values.city,
            zip_code: values.zip_code,
            products_id: values.products_id,
            payment_id: values.payment_id,
            payment_method: values.payment_method,
            start_date: values.start_date,
            // expired_date: values.expired_date,
            status: values.status,
            price: values.price,
            card_code: values.card_code,
        };

        return await axios
            .post(
                "https://hello-backend.kitereative.com/api/bestari/purchase-card",
                postData,
                {
                    headers: authTokenType,
                }
            )
            .then((response) => {
                // console.log(response.data);
                if (response.data.message === "true") {
                    // Notifification success
                    toast.success("Add Purchase Success!", {
                        position: "top-center",
                        autoClose: 2000, //2 detik
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate("/purchase");
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error("Add Purchase Failed", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            });
        // register(values.name, values.email, values.password, values.photo);
        // console.log(JSON.stringify(values, null, 2));
    };
    return (
        <>
            <div className="section-header">
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <h1>Purchases</h1>
            </div>
            <div className="card card-info">
                <div className="card-header">
                    <h4>Add Purchase</h4>
                </div>
                <div className="card-body ">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {(props) => {
                                    return (
                                        <Form method="POST">
                                            {loading ? (
                                                <p>Loading</p>
                                            ) : (
                                                <>
                                                    {/* <div className="form-group">
                        <label htmlFor="nick_name">Nick Name</label>
                        <Field
                          id="nick_name"
                          type="text"
                          className="form-control"
                          name="nick_name"
                        />
                        <ErrorMessage name="nick_name">
                          {(error) => (
                            //   <div className="invalid-feedback">{error}</div>
                            <div className="text-danger">{error}</div>
                          )}
                        </ErrorMessage>
                      </div> */}
                                                    <div className="form-group">
                                                        <label>User Name</label>
                                                        <Select
                                                            options={data}
                                                            name="users_id"
                                                            isClearable
                                                            // onChange={(e) => console.log(e)}
                                                            onChange={(e) => {
                                                                if (e) { //jika ada pilihan
                                                                    console.log(e);
                                                                    props.setFieldValue("users_id", e.value);
                                                                    setAutoCompleteData({
                                                                        ...autoCompleteData,
                                                                        email: e.email,
                                                                        phone: e.phone,
                                                                        address: e.address,
                                                                        city: e.city,
                                                                        zip_code: e.zip_code,
                                                                    });
                                                                    props.setFieldValue("email", e.email);
                                                                    props.setFieldValue("phone", e.phone);
                                                                    props.setFieldValue("address", e.address);
                                                                    props.setFieldValue("city", e.city);
                                                                    props.setFieldValue("zip_code", e.zip_code);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage name="users_id">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Product Name</label>
                                                        <Select
                                                            options={dataProduct}
                                                            name="products_id"
                                                            // onChange={(e) => console.log(e)}
                                                            onChange={(e) => {
                                                                // console.log(e);
                                                                props.setFieldValue("products_id", e.value);
                                                                setAutoCompleteData({ ...autoCompleteData, price: e.price });
                                                                props.setFieldValue("price", e.price); // Tambahkan baris ini untuk mengatur nilai price di Formik
                                                                const isProductSelected = !!e.value; // Cek apakah produk dipilih
                                                                setDisabledInput(isProductSelected); // Update state untuk disable/enable input price
                                                            }}
                                                        />
                                                        <ErrorMessage name="products_id">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="recipient">Recipient</label>
                                                        <Field
                                                            id="recipient"
                                                            type="text"
                                                            className="form-control"
                                                            name="recipient"
                                                        />
                                                        <ErrorMessage name="recipient">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="email">Email</label>
                                                        <Field
                                                            id="email"
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={autoCompleteData.email ?? ''}
                                                            onChange={(e) => {
                                                                props.setFieldValue("email", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, email: e.target.value }); // Update state autoCompleteData
                                                                props.setFieldValue("email", e.email); // Tambahkan baris ini untuk mengatur nilai email di Formik
                                                            }}
                                                        />
                                                        <ErrorMessage name="email">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="phone">Phone</label>
                                                        <Field
                                                            id="phone"
                                                            type="number"
                                                            className="form-control"
                                                            name="phone"
                                                            value={autoCompleteData.phone ?? ''}
                                                            onChange={(e) => {
                                                                props.setFieldValue("phone", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, phone: e.target.value }); // Update state autoCompleteData
                                                                props.setFieldValue("phone", e.phone); // Tambahkan baris ini untuk mengatur nilai phone di Formik
                                                            }}
                                                        />
                                                        <ErrorMessage name="phone">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="Textarea1">Address</label>
                                                        <Field
                                                            as="textarea"
                                                            className="form-control"
                                                            id="Textarea1"
                                                            name="address"
                                                            rows="30"
                                                            placeholder="Address"
                                                            style={{ height: "100px" }}
                                                            value={autoCompleteData.address ?? ''}
                                                            onChange={(e) => {
                                                                props.setFieldValue("address", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, address: e.target.value }); // Update state autoCompleteData
                                                                props.setFieldValue("address", e.address); // Tambahkan baris ini untuk mengatur nilai address di Formik
                                                            }}
                                                        />
                                                        <ErrorMessage name="address">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="city">City</label>
                                                        <Field
                                                            id="city"
                                                            type="text"
                                                            className="form-control"
                                                            name="city"
                                                            value={autoCompleteData.city ?? ''}
                                                            onChange={(e) => {
                                                                props.setFieldValue("city", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, city: e.target.value }); // Update state autoCompleteData
                                                                props.setFieldValue("city", e.city); // Tambahkan baris ini untuk mengatur nilai city di Formik
                                                            }}
                                                        />
                                                        <ErrorMessage name="city">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="zip_code">Zip Code</label>
                                                        <Field
                                                            id="zip_code"
                                                            type="number"
                                                            className="form-control"
                                                            name="zip_code"
                                                            value={autoCompleteData.zip_code ?? ''}
                                                            onChange={(e) => {
                                                                props.setFieldValue("zip_code", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, zip_code: e.target.value }); // Update state autoCompleteData
                                                                props.setFieldValue("zip_code", e.zip_code); // Tambahkan baris ini untuk mengatur nilai zip_code di Formik
                                                            }}
                                                        />
                                                        <ErrorMessage name="zip_code">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="payment_id">Payment Id</label>
                                                        <Field
                                                            id="payment_id"
                                                            type="text"
                                                            className="form-control"
                                                            name="payment_id"
                                                        />
                                                        <ErrorMessage name="payment_id">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="payment_method">
                                                            Payment Method
                                                        </label>
                                                        <Field
                                                            id="payment_method"
                                                            type="text"
                                                            className="form-control"
                                                            name="payment_method"
                                                        />
                                                        <ErrorMessage name="payment_method">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="start_date">Date Purchase</label>
                                                        <Field
                                                            id="start_date"
                                                            type="date"
                                                            className="form-control"
                                                            name="start_date"
                                                        />
                                                        <ErrorMessage name="start_date">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    {/* <div className="form-group">
                            <label htmlFor="expired_date">Expired Date</label>
                            <Field
                              id="expired_date"
                              type="date"
                              className="form-control"
                              name="expired_date"
                            />
                            <ErrorMessage name="expired_date">
                              {(error) => (
                                <div className="text-danger">{error}</div>
                              )}
                            </ErrorMessage>
                          </div> */}

                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <Select
                                                            options={status}
                                                            name="status"
                                                            // onChange={(e) => console.log(e)}
                                                            onChange={(e) => {
                                                                props.setFieldValue("status", e.value);
                                                            }}
                                                        />
                                                        <ErrorMessage name="status">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="price">Price (€)</label>
                                                        <Field
                                                            // disabled
                                                            id="price"
                                                            type="text"
                                                            className="form-control"
                                                            name="price"
                                                            value={autoCompleteData.price}
                                                            onChange={(e) => {
                                                                props.setFieldValue("price", e.target.value); // Update nilai price di Formik
                                                                setAutoCompleteData({ ...autoCompleteData, price: e.target.value }); // Update state autoCompleteData
                                                            }}
                                                            disabled={disabledInput} // Tambahkan prop disabled sesuai dengan state priceDisabled
                                                        />
                                                        <ErrorMessage name="price">
                                                            {(error) => (
                                                                <div className="text-danger">{error}</div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="card_code">Card Code</label>
                                                        <Field
                                                            id="card_code"
                                                            placeholder="XXXX-XXXX-XXXX"
                                                            type="text"
                                                            className="form-control w-50"

                                                            name="card_code"
                                                            maxLength={14} // Batasi inputan hanya sampai 14 karakter
                                                            onChange={(e) => {
                                                                const cardCode = e.target.value.toUpperCase(); // Mengubah setiap huruf menjadi kapital;
                                                                const formattedCardCode = cardCode
                                                                    .replace(/\s/g, '') // Menghapus spasi dari cardCode
                                                                    .replace(/-/g, '') // Menghapus tanda - dari cardCode
                                                                    .match(/.{1,4}/g) // Memisahkan cardCode menjadi array setiap 4 karakter
                                                                    ?.join('-'); // Menggabungkan array dengan tanda - setiap 4 karakter

                                                                props.setFieldValue('card_code', formattedCardCode); // Update nilai card_code di Formik
                                                                // Tambahkan validasi panjang 14 huruf sebelum memanggil fungsi checkCardCodeUniqueness
                                                                if (formattedCardCode && formattedCardCode.length === 14) {
                                                                    checkCardCodeUniqueness(formattedCardCode);
                                                                } else {
                                                                    setIsCardCodeUnique('');
                                                                }
                                                            }}
                                                        // value={values.card_code} // Gunakan values.card_code agar nilai terpantau oleh Formik
                                                        />

                                                        {isLoadingCekCode ? (
                                                            <span>Loading...</span> // Tampilkan pesan loading saat isLoading true
                                                        ) : isCardCodeUnique === 'available' ? (
                                                            <span className="text-success">
                                                                <CheckCircleIcon width="16" className="text-success mr-1" />
                                                                card code available
                                                            </span>
                                                        ) : isCardCodeUnique === 'unavailable' ? (
                                                            <span className="text-danger">
                                                                <XCircleIcon width="16" className="text-danger mr-1" />
                                                                card code already taken
                                                            </span>
                                                        ) : null}

                                                        <ErrorMessage name="card_code">
                                                            {(error) => <div className="text-danger">{error}</div>}
                                                        </ErrorMessage>
                                                    </div>

                                                    <div className="form-group">
                                                        <button
                                                            type="submit"
                                                            disabled={props.isSubmitting || !props.isValid}
                                                            className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                                                ? "btn-secondary"
                                                                : "btn-primary"
                                                                }`}
                                                        >
                                                            {props.isSubmitting ? "Please Wait" : "Add"}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="card-footer "></div>
            </div>
        </>
    );
};

export default AddPurchase;
