import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const TransactionPurchaseChart = ({ dataTransaction, dataPurchase }) => {
  const [dataVarTransaction, setDataVarTransaction] = useState(null);
  const [dataVarPurchase, setDataVarPurchase] = useState(null);

  const allDataTransact = dataTransaction?.data;
  const allDataPurchase = dataPurchase?.data;

  useEffect(() => {
    // Misalkan data transaksi Anda

    // Inisialisasi objek untuk menyimpan jumlah transaksi setiap bulan
    const jumlahTransaksiPerBulan = {
      1: 0, // Januari
      2: 0, // Februari
      3: 0, // Maret
      4: 0, // April
      5: 0, // Mei
      6: 0, // Juni
      7: 0, // Juli
      8: 0, // Agustus
      9: 0, // September
      10: 0, // Oktober
      11: 0, // November
      12: 0, // Desember
    };

    const jumlahPurchasePerBulan = {
      1: 0, // Januari
      2: 0, // Februari
      3: 0, // Maret
      4: 0, // April
      5: 0, // Mei
      6: 0, // Juni
      7: 0, // Juli
      8: 0, // Agustus
      9: 0, // September
      10: 0, // Oktober
      11: 0, // November
      12: 0, // Desember
    };

    // ---------------------------------------------------------------
    // Mengelompokkan dan menghitung jumlah transaksi setiap bulan
    allDataTransact?.forEach((transaksi) => {
      // const tanggal = new Date(transaksi.start_date);
      const tanggal = new Date(transaksi.created_at);
      const bulan = tanggal.getMonth() + 1; // getMonth() mulai dari 0, tambahkan 1
      jumlahTransaksiPerBulan[bulan] += transaksi ? 1 : 0;
      // jumlahTransaksiPerBulanPurchase[bulan] +=
      //   transaksi.status === "REJECTED" ? 1 : 0;
    });
    // ---------------------------------------------------------------
    // Mengelompokkan dan menghitung jumlah purchase setiap bulan
    allDataPurchase?.forEach((transaksi) => {
      // const tanggal = new Date(transaksi.start_date);
      const tanggal = new Date(transaksi.created_at);
      const bulan = tanggal.getMonth() + 1; // getMonth() mulai dari 0, tambahkan 1
      jumlahPurchasePerBulan[bulan] += transaksi ? 1 : 0;
      // jumlahTransaksiPerBulanPurchase[bulan] +=
      //   transaksi.status === "REJECTED" ? 1 : 0;
    });

    setDataVarTransaction(Object.values(jumlahTransaksiPerBulan));
    setDataVarPurchase(Object.values(jumlahPurchasePerBulan));
  }, []);

  const dataChart = {
    type: "line",
    // labels: tahunBulanSuccess,
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sept",
      "Oct",
      "Nov",
      "Des",
    ],
    datasets: [
      {
        fill: true,
        label: "Transaction",
        // data: [3200, 1800, 4305, 3022, 6310, 5120, 5880, 6154],
        data: dataVarTransaction,
        borderWidth: 2,
        backgroundColor: "rgba(63,82,227,.8)",
        borderColor: "transparent",
        pointBorderWidth: 0,
        pointRadius: 3.5,
        // pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "rgba(63,82,227,.8)",
        lineTension: 0.3, //curve
      },
      {
        fill: true,
        label: "Purchase",
        // data: [2207, 3403, 2200, 5025, 2302, 4208, 3880, 4880],
        data: dataVarPurchase,

        borderWidth: 2,
        backgroundColor: "rgba(254,86,83,.7)",
        borderColor: "transparent",
        pointBorderWidth: 0,
        pointRadius: 3.5,
        // pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "rgba(254,86,83,.8)",
        lineTension: 0.3, //curve
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          // display: false,
          // drawOnChartArea: true,
          // drawTicks: true,
          drawBorder: false,
          color: "#f2f2f2",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function (value, index, values) {
            // return "$" + value;
            return value;
          },
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          // drawOnChartArea: true,
          tickMarkLength: 15,
        },
      },
    },
  };
  console.log(allDataTransact);
  return (
    <div style={{ height: "332px" }}>
      <Line data={dataChart} options={options} />
    </div>
  );
};

export default TransactionPurchaseChart;
