import React from "react";
import logo from "./../HelloHome.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useDispatch } from "react-redux";
import { userLogin } from "../features/userSlice";
import { NavLink } from "react-router-dom";

const Login = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const initialValues = {
        email: "",
        password: "",
    };
    const validationSchema = yup.object({
        email: yup.string().required().email().trim(),
        password: yup.string().required().trim().min(6),
    });

    const onSubmit = async (values) => {
        await AuthService.login(values.email, values.password)
            .then((response) => {
                console.log(response);
                const userInfo = {
                    //'photoURL dll' dapat dari console.log(user) utk dimasukkan k dlm Statemanagement (Redux)
                    userId: response.id,
                    displayName: response.name,
                    email: response.email,
                    emailVerifiedAt: response.email_verified_at,
                    photoURL: response.image,
                    token: response.token,
                    roles: response.roles,
                };
                if (response.message === "login success") {
                    // Notifification success
                    toast.success("Login Success!", {
                        position: "top-center",
                        autoClose: 2000, //2 detik
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    console.log(response);
                    setTimeout(() => {
                        dispatch(userLogin(userInfo));
                        // navigate("/register");
                        // window.location.reload();
                    }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                toast.error("Login Failed", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    };
    return (
        <section className="section">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <div className="login-brand">
                            <img
                                src={logo}
                                alt="logo"
                                width="300"
                                // className="shadow-light rounded-circle"
                                className=""
                            />
                            {/* Logo */}
                        </div>

                        <div className="card card-primary">
                            <div className="card-header">
                                <h4>Login</h4>
                            </div>

                            <div className="card-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {(props) => {
                                        return (
                                            <Form
                                                method="POST"
                                                // action="#"
                                                className="needs-validation"
                                            >
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <Field
                                                        id="email"
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        required
                                                    />
                                                    <ErrorMessage name="email">
                                                        {(error) => (
                                                            <div className="text-danger">{error}</div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>

                                                <div className="form-group">
                                                    <div className="d-block">
                                                        <label htmlFor="password" className="control-label">
                                                            Password
                                                        </label>
                                                        {/* <div className="float-right">
                        <a
                          href="auth-forgot-password.html"
                          className="text-small"
                        >
                          Forgot Password?
                        </a>
                      </div> */}
                                                    </div>
                                                    <Field
                                                        id="password"
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        required
                                                    />
                                                    <ErrorMessage name="password">
                                                        {(error) => (
                                                            <div className="text-danger">{error}</div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>

                                                {/* <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        id="remember-me"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="remember-me"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div> */}

                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        disabled={props.isSubmitting || !props.isValid}
                                                        className={`btn btn-lg btn-block ${props.isSubmitting || !props.isValid
                                                            ? "btn-secondary"
                                                            : "btn-primary"
                                                            }`}
                                                    >
                                                        {props.isSubmitting ? "Please Wait" : "Login"}
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                        {/* <div className="mt-5 text-muted text-center">
              Don't have an account?
              <NavLink to={"/register"}> Create One</NavLink>
            </div> */}
                        {/*<div className="mt-5 text-muted text-center">
              Go to Company List
              <NavLink to={"/"}> Company List</NavLink>
            </div>
             <div className="mt-5 text-muted text-center">
              Go to Chat Company
              <NavLink to={"/public/chat"}> Chat Company</NavLink>
            </div> */}
                        <div className="simple-footer">
                            Copyright &copy; HelloHome 2024
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
