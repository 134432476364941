import React, { useEffect, useState } from "react";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import DataTable from "react-data-table-component";
import auth from "../services/AuthHeader";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import noImage from "./../assets/images/img09.jpg";

const Transaction = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  let warna;
  // let status = "pending";
  let [status, setStatus] = useState("PENDING");

  if (status === "PENDING") {
    warna = "warning";
  } else if (status === "SUCCESS") {
    warna = "success";
  } else {
    warna = "danger";
  }
  let [colorStatus, setColorStatus] = useState(warna);

  const columns = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      // grow: -1,
      center: true,
      width: "80px",

      // sortable: true,
    },
    {
      name: "Subscription ID",
      selector: (row) => <span className="font-weight-bold">HH-{row.id}</span>,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => <NavLink>{row.user?.name}</NavLink>,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        // row.status === "PENDING" && (
        //   <span className="badge badge-warning">{row.status}</span>
        // );
        // row.status === "SUCCESS" && (
        //   <span className="badge badge-success">{row.status}</span>
        // );
        // row.status === "REJECTED" && (
        //   <span className="badge badge-danger">{row.status}</span>
        // );

        row.status === "PENDING" ? (
          <span className={`badge badge-warning`}>{row.status}</span>
        ) : row.status === "SUCCESS" ? (
          <span className={`badge badge-success`}>{row.status}</span>
        ) : (
          <span className={`badge badge-danger`}>{row.status}</span>
        ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => "€ " + row.price,
      sortable: true,
    },
    // {
    //   name: "Image",
    //   // selector: (row) => row.image,
    //   selector: (row) => (
    //     <img
    //       height={70}
    //       width={80}
    //       src={"https://hello-backend.kitereative.com/storage/app/public/" + row.image}
    //       alt=""
    //     />
    //   ),
    // },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <div className="flex justify-conten-center">
          <NavLink
            className="btn btn-info mx-1 my-1"
            to={`/transaction/details/${row.id}`}
          >
            <EyeIcon width="18" />
          </NavLink>

          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => handleClickDelete(row.id)}
          >
            <TrashIcon width="18" />
          </button>
        </div>
      ),
    },
  ];

  const getTransactions = async () => {
    try {
      const req = await fetch(
        "https://hello-backend.kitereative.com/api/bestari/transaction",
        {
          headers: authTokenType,
        }
      );
      const res = await req.json();
      setData(res.data);
      setFilter(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    const result = data.filter((item) => {
      return (
        // item.status.toLowerCase().match(search.toLocaleLowerCase())
        // ||
        item.id.toString().toLowerCase().match(search.toLocaleLowerCase())
      );

    });
    setFilter(result);
  }, [search]);

  // delete modal
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDelete = () => {
    // console.log(id);
    // const newdata = data.filter((item) => item.id !== deleteId);
    // setFilter(newdata);

    return axios
      .delete(
        `https://hello-backend.kitereative.com/api/bestari/transaction/${deleteId}`,
        {
          headers: authTokenType,
        }
      )
      .then((res) => {
        setShow(false);
        setSearch("");
        getTransactions();
        console.log(res);
      }); //karena membutuhkan id, jadi seperti ini
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#408ACA",
      },
    },
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="section-header">
        <h1>Subscriptions</h1>
      </div>

      {/* <div className="section-body"> */}
      <div className="card">
        <div className="card-header">
          <h4>All Subscriptions</h4>
        </div>
        <DataTable
          className="px-4"
          customStyles={tableHeaderstyle}
          columns={columns}
          // data={data}
          data={filter}
          pagination
          // selectableRows
          fixedHeader
          fixedHeaderScrollHeight="600px"
          selectableRowsHighlight
          highlightOnHover
          // actions={
          //   <NavLink end to={"/add-user"} className="btn btn-success">
          //     Add Transaction
          //   </NavLink>
          // }
          subHeader
          subHeaderComponent={
            <>
              {/* <label className="text-primary mr-1">HH- </label> */}
              <div className="input-group mb-3 w-25">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">HH -</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </>

          }
          subHeaderAlign="right"
        ></DataTable>
      </div>
    </>
  );
};

export default Transaction;
