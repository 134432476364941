import React, { useEffect, useState } from "react";
import bannerUserProduct from "./../assets/images/show-image.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import noImage from "./../assets/images/avatar-1.png";
import imgCard from "./../assets/images/img_card.png";


const CompanyList = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  // Filter data yang memiliki roles "owner"
  // const filteredDatas = datas.filter((data) => data.roles === 'OWNER');

  const getUser = async () => {
    try {
      const req = await fetch(
        // "https://hello-backend.kitereative.com/api/auth/user-without-auth"
        "https://hello-backend.kitereative.com/api/auth/owner-where-hash-card-code"
      );
      const res = await req.json();
      setDatas(res.data);
      // setFilter(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  console.log(datas);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "100px",
      }}
    >
      <div className="row sticky-top" style={{ backgroundColor: "#408ACA" }}>
        <div className="col text-center text-white my-3">
          <span className="mx-auto font-weight-bold">Bienvenue</span>
          <div className="d-flex ">
            <select className="language ml-auto font-weight-bold text-white bg-transparent">
              {/* <option value="1">One</option> */}
              <option className="font-weight-bold text-primary">FRENCH</option>
              <option className="font-weight-bold text-primary">ENGLISH</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col text-right">
          <NavLink
            to={"/login"}
            className="btn btn-primary py-2 px-3 mt-4">Login
          </NavLink>
        </div>
      </div>
      <div className="row my-3">
        <div className="col text-center text-white">
          <h5 className="font-weight-bold text-dark">
            Merci de sélectionner la société que vous venez visiter
          </h5>
        </div>
      </div>
      <div className=" text-center ">
        {loading ? (
          <div className="spinner-border mt-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="row">
            {datas.map((data, i) => (
              <div className="col-6" key={i} style={{ marginBottom: "20px" }}>
                <NavLink
                  to={`/company/view/${data?.card_code_hash}`} // Ganti ini dengan cara yang sesuai untuk mendapatkan ID atau kunci unik dari staff
                  className=""
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="card text-white"
                    style={{
                      // backgroundColor: "#231F20",
                      borderRadius: "20px",
                      backgroundImage: `url(${imgCard})`, // Ganti 'path/to/img_card.png' dengan path gambar Anda
                      backgroundSize: "cover", // Mengatur agar gambar latar belakang menutupi seluruh div
                      width: "100%", // Panjang div
                      height: "300px", // Lebar div
                      // height: "100%", // Tinggi card 100%
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between", // Untuk memberikan ruang antara elemen di dalam card
                    }}
                  >
                    <div className="row justify-content-around my-auto">
                      <div className="col" >
                        {/* display sm keatas */}
                        <div className="d-none d-sm-block">
                          <h1 className="pt-2 text-uppercase text-primary d-md-none">{data.user.name ?? "empty"}</h1>
                          <div className="mx-auto "
                            style={{
                              width: "200px",
                              height: "200px",
                              overflow: "hidden",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              src={
                                data.user.image
                                  ? `https://hello-backend.kitereative.com/storage/app/public/${data.user.image}`
                                  : noImage
                              }
                              className="img-fluid rounded-circle"
                              alt=""
                              // style={{ maxWidth: "160px" }}
                              style={{
                                border: "6px solid #00A5DA",
                                width: "100%",
                                height: "100%",
                                // borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                        {/* display mobile */}
                        <div className="d-block d-sm-none">
                          <h6 className="pt-2 text-uppercase text-white">{data.user.name ?? "empty"}</h6>
                          <div className="mx-auto "
                            style={{
                              width: "130px",
                              height: "130px",
                              overflow: "hidden",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              src={
                                data.user.image
                                  ? `https://hello-backend.kitereative.com/storage/app/public/${data.user.image}`
                                  : noImage
                              }
                              className="img-fluid rounded-circle"
                              alt=""
                              // style={{ maxWidth: "160px" }}
                              style={{
                                border: "6px solid #00A5DA",
                                width: "100%",
                                height: "100%",
                                // borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col d-none d-md-flex flex-column" style={{ borderLeft: '4px solid #0076CB' }}>
                        <h3 className="pt-2 text-uppercase text-dark">{data.user.name ?? "empty"}</h3>
                        <p className="font-16 text-danger mt-n2  text-uppercase">{data.user.city ?? "empty"}</p>
                        <div className="mb-0 pb-0 text-capitalize text-dark"><h5>{data.user.address ?? "empty"}</h5></div>
                        <div className="mb-0 pb-0 text-capitalize text-dark">{data.user.zip_code ?? "empty"}</div>
                      </div>
                    </div>
                    {/* <div>
                      <h3 className="pt-2 text-uppercase">{data.name ?? "empty"}</h3>
                      <p className="font-16 text-danger mt-n2 mb-n1 text-uppercase">{data.city ?? "empty"}</p>
                      <div className="mb-0 pb-0 text-capitalize">{data.address ?? "empty"}</div>
                      <div className="mb-0 pb-0 text-capitalize">{data.zip_code ?? "empty"}</div>
                    </div> */}
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        )}

      </div>
      <footer>
        <div
          className="fixed-bottom py-2"
          style={{ backgroundColor: "#408ACA" }}
        >
          <NavLink style={{ textDecoration: "none" }}>
            <p className="text-center text-white">hellohome.casa</p>
          </NavLink>
        </div>
      </footer>
    </div>
  );
};

export default CompanyList;
