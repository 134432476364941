import React, { useRef } from "react";
import io from "socket.io-client";
import { useEffect, useState } from "react";
import axios from "axios";
import auth from "../services/AuthHeader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import logo from "./../HelloHome.png";
import { useNavigate } from "react-router-dom";
import noImage from "./../assets/images/avatar-1.png";
import CountdownTimer from './CountdownTimer';



const socket = io.connect("https://node.chatnew.kitereative.com");

const Chat = () => {
  const authTokenType = auth.AuthHeaderWithType();

  const { idstaff, namestaff, idroom, imagestaff } = useParams();
  console.log(namestaff);

  // const authTokenType = auth.AuthHeader();
  const userId = useSelector((state) => state.user);
  // const uid = userId ? userId.userId : generateRandomId();
  const uid = userId ? userId.userId : null;
  console.log(uid);

  const lastMessageRef = useRef(null);

  //Room State
  // const [room, setRoom] = useState("");
  // const [room, setRoom] = useState(203);
  const [room, setRoom] = useState(idroom);

  // Messages States
  const [message, setMessage] = useState("Hello");
  // const [messageReceived, setMessageReceived] = useState("");
  // const [todos, setTodos] = useState([
  //   { name: "umar", kelas: 12 },
  //   { name: "ridwan", kelas: 1 },
  // ]);

  // message dari lawan bicara
  const [dataMessages, setDataMessages] = useState([]);

  // message dari saya
  const [dataMyMessages, setDataMyMessages] = useState([]);

  // user connect
  const [userConnected, setUserConnected] = useState(null);

  // console.log(id);

  const [testMessage, setTestMessage] = useState();

  const [stopCountdown, setStopCountdown] = useState(false);
  const navigate = useNavigate();

  // scroll
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({
      behavior: "smooth",
      // block: "end",
    });
    // }, [message]);
  }, [message]);

  // useEffect(() => {
  // }, [seconds, navigate, stopCountdown]);

  // mengirim notifikasi saat calon cust pertama-tama masuk kehalaman chat(tanpa mengirim pesan)
  useEffect(() => {
    if (idstaff !== "" && room !== "") {
      console.log(message);
      socket.emit("join_room", room);
      console.log("Join Room");
      console.log(room);
      const convert_idstaff = Number(idstaff);
      socket.emit("room_listen_chat", convert_idstaff);
      // console.log(typeof idstaff);
      // console.log(typeof convert_idstaff)
      console.log(convert_idstaff);
      // Mendapatkan tanggal sekarang
      const today = new Date();

      // Mengubah tanggal ke dalam format yang diinginkan (misalnya: "YYYY-MM-DD")
      const date = today.toISOString().split("T")[0];

      // Mendapatkan waktu sekarang dlm format 24 jam
      const hours = today.getHours().toString().padStart(2, "0"); // Ambil jam dengan format dua digit (00-23)
      const minutes = today.getMinutes().toString().padStart(2, "0"); // Ambil menit dengan format dua digit (00-59)
      const seconds = today.getSeconds().toString().padStart(2, "0"); // Ambil detik dengan format dua digit (00-59)
      const time = `${hours}:${minutes}:${seconds}`;
      console.log(idstaff, room);
      const messages = message;
      const room_id = room;
      const id_cust = uid;
      const id_staff = idstaff;
      const type = "chat";
      const status = 0;
      console.log(convert_idstaff, messages, id_staff, room_id, id_cust, date, time, type, status)

      const sendDB = async () => {
        const transformedData = {
          messages,
          room_id,
          id_cust,
          id_staff,
          date,
          time,
        };

        try {
          // Making a POST request to your server using Axios
          const response = await axios.post('https://hello-backend.kitereative.com/api/auth/chat-activity', transformedData);
          socket.emit("send_message", { message, room, uid, idstaff, date, time });

          // Handle the response if needed
          console.log('Message sent successfully:', response);
          socket.emit("send_listen_chat", { convert_idstaff, messages, id_staff, room_id, id_cust, date, time, type, status });

        } catch (error) {
          // Handle errors if the request fails
          console.error('Error sending message:', error);
        }
      };

      // Panggil fungsi sendDB
      sendDB();
      setMessage("");

    }
  }, []);

  const handleTimeout = () => {
    navigate(-1); // Navigate back when the countdown reaches zero
  };





  // user connected
  socket.on("userConnect", (data) => {
    // console.log(data);
    // if (userId) {
    setUserConnected(data);

    // setRoom(data + '_203');
    // }
  });
  console.log(userConnected);
  console.log(room);

  // Mengirim pesan
  const handleSendMessage = async (e) => {
    // socket.emit("send_message", { message: "Hello" });
    // socket.emit("send_message", { message: message });

    // Mendapatkan tanggal sekarang
    const today = new Date();

    // Mengubah tanggal ke dalam format yang diinginkan (misalnya: "YYYY-MM-DD")
    const date = today.toISOString().split("T")[0];

    // Mendapatkan waktu sekarang dlm format 24 jam
    const hours = today.getHours().toString().padStart(2, "0"); // Ambil jam dengan format dua digit (00-23)
    const minutes = today.getMinutes().toString().padStart(2, "0"); // Ambil menit dengan format dua digit (00-59)
    const seconds = today.getSeconds().toString().padStart(2, "0"); // Ambil detik dengan format dua digit (00-59)
    const time = `${hours}:${minutes}:${seconds}`;

    e.preventDefault();
    // console.log(message, room, uid, idstaff, date, time)

    socket.emit("send_message", { message, room, uid, idstaff, date, time }); //mengirim pesan sesuai dengan id room nya


    setMessage("");

  };

  const handleEndButton = () => {
    // Mendapatkan tanggal sekarang
    const today = new Date();

    // Mengubah tanggal ke dalam format yang diinginkan (misalnya: "YYYY-MM-DD")
    const date = today.toISOString().split("T")[0];

    // Mendapatkan waktu sekarang dlm format 24 jam
    const hours = today.getHours().toString().padStart(2, "0"); // Ambil jam dengan format dua digit (00-23)
    const minutes = today.getMinutes().toString().padStart(2, "0"); // Ambil menit dengan format dua digit (00-59)
    const seconds = today.getSeconds().toString().padStart(2, "0"); // Ambil detik dengan format dua digit (00-59)
    const time = `${hours}:${minutes}:${seconds}`;


    // console.log(message, room, uid, idstaff, date, time)
    const message = "end chat";
    console.log(message, room, uid, idstaff, date, time);
    socket.emit("send_message", { message, room, uid, idstaff, date, time });
    navigate(-1);
  };
  // console.log(dataMyMessages);

  useEffect(() => {
    socket.on("mydata", (data) => {
      console.log(data);
      setDataMyMessages((prevDatas) => [...prevDatas, data]);
    });
  }, [socket]);
  console.log(dataMyMessages);

  // mendengarkan acara dari backend
  useEffect(() => {
    socket.on("receive_message", (data) => {
      console.log(data);
      setDataMyMessages((prevDatas) => [...prevDatas, data]);
      setTestMessage(data);
      setStopCountdown(true);
      // setDataMessages(data.data.data); //automatis 'todos' isinya adl 'res.data'
      // console.log(data.message);
      // setMessageReceived(data.message);
      lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }, [socket]); // setiap kali peristiwa di pancarkan, maka akan mendengarkan
  // console.log(dataMessages);
  console.log(dataMyMessages);
  console.log(testMessage);



  return (
    <div>
      <div
        className="card chat-box card-success container "
        // style={{ overflowY: "scroll" }}
        id="mychatbox2"
      >
        <div className="login-brand">
          <img
            src={logo}
            alt="logo"
            width="300"
            // className="shadow-light rounded-circle"
            className=""
          />
          {/* Logo */}
          <h3 className="text-center mt-3">Une seconde SVP</h3>
        </div>
        <div className="d-flex justify-content-end">
          <button type="button" onClick={handleEndButton} className="btn btn-outline-danger ">End Chat</button>
        </div>
        <div className="card-header ">
          <h4 className="mx-auto text-capitalize">
            <i
              className="fas fa-circle text-success mr-2"
              title=""
              data-toggle="tooltip"
              data-original-title="Online"
            ></i>
            {namestaff}
          </h4>
        </div>
        {/* {stopCountdown ? null : <h6 className="text-center">{formatTime(seconds)} BEFORE RESCANNING</h6>} */}
        {stopCountdown ? null : (
          <CountdownTimer initialSeconds={60} onTimeout={handleTimeout} />
        )}
        <div
          className="card-body chat-content"
          // tabindex="3"
          style={{ outline: "none", paddingBottom: "50px", overflowY: "scroll" }}
        // lastMessageRef={lastMessageRef}
        >
          {dataMyMessages.map((dataMessage, i) =>
            // userId.userId == dataMessage.uid ? (
            dataMessage.uid === null ? (
              <div
                className={`chat-item ${uid ? "chat-left" : "chat-right"}`}
                key={i}
              >
                {/* <img src="../dist/img/avatar/avatar-2.png" /> */}
                <img src={logo}
                  className="img-fluid rounded-circle"
                  alt=""
                  // style={{ maxWidth: "160px" }}
                  style={{
                    width: "40px",
                    height: "40px",
                    // borderRadius: "50%",
                    objectFit: "cover",
                  }} />
                <div className="chat-details">
                  {dataMessage.message === 'end chat' ?
                    <span className="text-sm text-center badge badge-pill badge-secondary"><span className="text-capitalize">{namestaff}</span> à quitté le Tchat.</span>
                    :
                    <div className="chat-text">{dataMessage.message}</div>
                  }
                  {/* <div className="chat-text">{dataMessage.message === 'end chat' ? `${namestaff} à quitté le Tchat.` : dataMessage.message}</div> */}
                  {/* <div className="chat-time">04:14</div> */}
                </div>
              </div>
            ) : (
              <div
                className={`chat-item ${!uid ? "chat-left" : "chat-right"}`}
                key={i}
              >
                <img src={imagestaff === 'noimage' ? noImage : `https://hello-backend.kitereative.com/storage/app/public/assets/gallery/${imagestaff}`}
                  className="img-fluid rounded-circle"
                  alt=""
                  // style={{ maxWidth: "160px" }}
                  style={{
                    width: "40px",
                    height: "40px",
                    // borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <div className="chat-details">
                  {dataMessage.message === 'end chat' ?
                    <span className="text-sm text-center badge badge-pill badge-secondary"><span className="text-capitalize">{namestaff}</span> à quitté le Tchat.</span>
                    :
                    <div className="chat-text">{dataMessage.message}</div>
                  }
                  {/* <div className="chat-time">04:14</div> */}
                </div>
              </div>
            )
          )}

          <div ref={lastMessageRef} />
        </div>

        <div className="card-footer chat-form ">
          <form id="chat-form2" onSubmit={handleSendMessage}>
            <input
              type="text"
              className="form-control "
              placeholder="Type a message"
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
            <button className="btn btn-primary">
              <i className="far fa-paper-plane"></i>
            </button>
            {/* onClick={sendMessage} */}
          </form>
        </div>
      </div>
      <h3 className="text-center mt-5">GET YOURS NOW!</h3>
      <h3 className="text-center">WWW.HELLOHOME.CASA</h3>
      {/* <input
        placeholder="Room Number..."
        onChange={(event) => {
          setRoom(event.target.value);
        }}
      /> */}
      {/* <p>{room}</p> */}
      {/* <p>{formattedDate}</p> */}
      {/* <p>{currentTime.toLocaleTimeString()}</p> */}
      {/* <button onClick={joinRoom}> Join Room</button> */}
      {/* <input
        placeholder="Message.."
        onChange={(event) => {
          setMessage(event.target.value);
        }}
      /> */}
      {/* <button onClick={sendMessage}>Send Message</button> */}

    </div >

  );
};

export default Chat;
